import { Component, Input } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    AllChargeCategories,
    ChargeCategoryInfo,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    defaultRoleState,
    EntityTypes,
    PropertyType,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    PropertyService,
    RoleService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { ChargeDeleteComponent, StoryDisplayComponent, TreasuryList } from '@omedom/ui';
import { OmedomStory } from '@omedom/utils';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

@Component({
    selector: 'app-charge-list',
    templateUrl: './charge-list.component.html',
    styleUrls: ['./charge-list.component.scss'],
})
export class ChargeListComponent extends TreasuryList<
    ChargeEntity,
    AllChargeCategories,
    ChargePeriodicity
> {
    @Input() isChargeForAllProperty?: boolean;
    /**
     * @description: title of the page: "Charge du mois" when in property; "Charges globales" when in treasury
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof ChargeListComponent
     */
    label: string;

    /**
     *@description: Only show property avatar card when in treasury
     * @author: Hanane Djeddal.
     * @type {boolean}
     * @memberof ChargeListComponent
     */
    avatar: boolean;

    /**
     * @description Previous url to assign to the back button
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ChargeListComponent
     */
    previousUrl: string;

    roleRight = defaultRoleState as RoleState;

    propertyUid: string;
    societyUid: string;
    isSociety: boolean;
    canCreateCharge = false;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        activatedRoute: ActivatedRoute,
        modalController: ModalController,
        router: Router,
        societyService: SocietyService,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private chargeService: ChargeService,
        private routerComp: Router,
        private analyticsService: AnalyticsService,
    ) {
        super(
            userService,
            propertyService,
            activatedRoute,
            modalController,
            router,
            societyService,
        );

        const segment = this.routerComp.url.split('/')[2];
        this.isSociety = this.routerComp.url.split('/')[3] === 'society';
        this.propertyUid =
            this.routerComp.url.split('/')[4] !== 'list' ? this.routerComp.url.split('/')[4] : null;
        if (segment === 'treasury') {
            this.previousUrl = '/tabs/treasury';
        } else {
            if (this.isSociety) {
                this.societyUid = this.routerComp.url.split('/')[5];
                this.previousUrl = '/tabs/property/society/info/' + this.societyUid;
            } else {
                this.previousUrl = '/tabs/property/info/' + this.propertyUid;
            }
        }
    }
    async ngOnInit(): Promise<void> {
        this.route.data.subscribe(async (data) => {
            this.manageDataSubscription(data);
        });
        super.ngOnInit();
    }

    async ionViewWillEnter() {
        super.ionViewWillEnter();
    }
    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Charge List Page');
    }

    private async manageDataSubscription(data: Data) {
        if (data.isChargeForAllProperty) {
            this.canCreateCharge = true;
        } else {
            await this.manageRoleState();
        }
    }
    private async manageRoleState() {
        if (this.isSociety) {
            await this.setRoleStateOfSociety();
        } else {
            await this.setRoleStateOfProperty();
        }
        this.canCreateCharge = this.roleRight?.create;
    }

    private async setRoleStateOfProperty() {
        const property = await this.propertyService.get(this.propertyUid);
        if (property) {
            this.roleRight = await this.roleService.getRoleState(property, EntityTypes.property);
        }
    }
    private async setRoleStateOfSociety() {
        const society = await this.societyService.get(this.societyUid);
        if (society) {
            this.roleRight = await this.roleService.getRoleState(society, EntityTypes.property);
        }
    }

    async deleteClicked(chargeListModel: ChargeListModel): Promise<void> {
        await this.showDeleteComponent(ChargeDeleteComponent, chargeListModel);
    }

    getCategoryInfo(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as AllChargeCategories);
    }

    getPeriodicityInfo(periodicity: string): ChargePeriodicityInfo {
        return new ChargePeriodicityInfo(periodicity as ChargePeriodicity);
    }

    loadTreasury(): Observable<ChargeEntity[]> {
        // let charges: Promise<ChargeEntity[]>;

        this.label = this.propertyUid || this.societyUid ? 'Charges du mois' : 'Charges globales';
        this.avatar = true;

        if (!!this.propertyUid) {
            return this.propertyService._get(this.propertyUid).pipe(
                switchMap((currentProperty) => {
                    this.avatar = currentProperty?.type === PropertyType.immeuble;
                    if (
                        (currentProperty?.lotsUID && currentProperty.lotsUID.length > 0) ||
                        currentProperty?.type === PropertyType.immeuble
                    ) {
                        return this.chargeService.getChargesByBuildingsLots(currentProperty);
                    }
                    return this.chargeService._getChargesByProperty(this.propertyUid);
                }),
            );
        }
        if (!!this.societyUid) {
            this.avatar = true;
            return this.chargeService._getChargesBySociety(this.societyUid);
        }
        const propertiesCharges = this.chargeService._getUserChargesByPropertyIds(
            this.properties.map((property) => property.uid),
        );
        const societyCharges = this.chargeService._getUserChargesBySocietyIds(
            this.societies.map((society) => society.uid),
        );
        return combineLatest([propertiesCharges, societyCharges]).pipe(
            map(([propertiesCharges, societyCharges]) => {
                return [...propertiesCharges, ...societyCharges];
            }),
        );
    }

    addClicked(): void {
        if (this.propertyUid) {
            this.routerComp.navigate([`/tabs/property/info/${this.propertyUid}/charge/form`], {
                queryParams: {
                    list: 'true',
                },
            });
        } else {
            this.routerComp.navigate(['/tabs/treasury/charge/form'], {
                queryParams: {
                    list: 'true',
                },
            });
        }
    }
    /**
     * @description Open modal for a story
     * @author ANDRE Felix
     * @param {ChargeListModel} chargeList
     * @returns {*}  {Promise<void>}
     * @memberof ChargeListComponent
     */
    async chargeClicked(chargeList: ChargeListModel): Promise<void> {
        this.analyticsService.logEvent('Story opened from charge list');
        const storyModel = OmedomStory.transformTreasuryModelListToStory(chargeList, true);
        const modal = await this.showStoryModal(StoryDisplayComponent, storyModel);
        modal.onDidDismiss().then((callback) => {
            if (
                callback.data?.updateType &&
                callback.data?.treasuryUid &&
                callback.data?.currentDate
            ) {
                if (callback.data.isCharge) {
                    this.routerComp.navigate([
                        '/tabs/treasury/charge/edit/' +
                            callback.data.treasuryUid +
                            '/' +
                            callback.data.updateType +
                            '/' +
                            callback.data.currentDate,
                    ]);
                } else {
                    this.routerComp.navigate([
                        '/tabs/treasury/income/edit/' +
                            callback.data.treasuryUid +
                            '/' +
                            callback.data.updateType +
                            '/' +
                            callback.data.currentDate,
                    ]);
                }
            } else {
                this.updateData();
            }
        });
    }

    getChargeInfo(propertyUID: string): {
        icon: string;
    } {
        const property = this.properties.find((p) => p.uid === propertyUID);
        const society = this.societies.find((s) => s.uid === propertyUID);
        let icon = 'uil uil-home';
        if (property) {
            icon = property.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        } else if (society) {
            icon = 'uil uil-suitcase';
        }
        return {
            icon,
        };
    }
}
