import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiModule } from '@omedom/ui';
import { NgChartsModule } from 'ng2-charts';

import { OmedomComponentModule } from '../component/omedom-component.module';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from './../widget/widget.module';
import { LiquidityComponent } from './liquidity/liquidity.component';
import { NewCgvValidationModalComponent } from './newCgv-validation-modal/newCgv-validation-modal.component';
import { FilterPropertyButtonComponent } from './property-list/filter-property-button/filter-property-button.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { PropertySearchComponent } from './property-search/property-search.component';
import {
    ReceiptRelaunchMissingFieldsComponent,
} from './receipt-relaunch-missing-fields/receipt-relaunch-missing-fields.component';
import {
    ReceiptRelaunchMissingTenantEmailComponent,
} from './receipt-relaunch-missing-tenant-email/receipt-relaunch-missing-tenant-email.component';
import { TenantFormModalComponent } from './tenant-form/tenant-form-modal.component';
import { UpdateComponent } from './update/update.component';

// import { RentCardComponent } from './rent-card/rent-card.component';
@NgModule({
    declarations: [
        FilterPropertyButtonComponent,
        LiquidityComponent,
        PropertyListComponent,
        PropertySearchComponent,

        TenantFormModalComponent,
        UpdateComponent,
        NewCgvValidationModalComponent,
        // RentCardComponent,
        ReceiptRelaunchMissingFieldsComponent,
        ReceiptRelaunchMissingTenantEmailComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        IonicModule,
        NgChartsModule,
        OmedomComponentModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        UiModule,
        WidgetModule,
    ],
    exports: [
        LiquidityComponent,

        PropertyListComponent,
        PropertySearchComponent,
        // RentCardComponent,
        TenantFormModalComponent,
    ],
    providers: [],
})
export class OmedomCommonModule {}
